import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { AComponentFactory } from "../ComponentFactory";
import Img from "@/entities/PrizificPageBuilder/components/Img";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";

export class ImgFactory extends AComponentFactory<Img> {
    getTypeName() {
        return EComponents.IMAGE;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, ["data.specific.src"]);

        return new Img(
            rawData.id,
            rawData.sharedComponentId ?? null,
            rawData.isSharedComponent ?? false,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents,
            rawData.data.specific.src ?? "-",
            rawData.data.specific.alt ?? "-"
        );
    }
}
