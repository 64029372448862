import { useCampaignDataStore } from "~~/store/campaign/campaignData";




export enum PrizificTrackingEvents {
    init = "init",
    enable = "enable",
    disable = "disable",
    pageView = "pageView",
    signUp = "signUp",
    login = "login",
    codeUpload = "codeUpload",
    codeUploadSuccess = "codeUploadSuccess"
}

/**
 * Custom solution to handle events
 */
class EventHandler {



    protected listeners: { [key: string]: Array<(...params: Array<any>) => void> } = {};

    /**
     * Subscribe to an event
     */
    subscribe(event: PrizificTrackingEvents, callback: (...params: Array<any>) => void) {
        if (this.listeners[event]) {
            this.listeners[event].push(callback);
        } else {
            this.listeners[event] = [callback];
        }
    }
    /**
     * Fire the given event 
     */
    fire(event: PrizificTrackingEvents, ...params: Array<any>) {

        if (this.listeners[event]) {
            this.listeners[event].forEach((callback) => {
                try {
                    callback(...params);
                } catch (e) {
                    console.error("Error in event callback", e);
                }
            });
        }
    }
    /**
     * Remove all events
     */
    reset() {
        this.listeners = {};
    }
}

/**
 * NOTICE: !!!!!!!!!!!!NEVER USE DIRECTLY!!!!!!!!!!!!
 */
class PrizGTAG {

    protected isInitialized = false;
    /**
     * Current state of the google analitics
     */
    protected enabled = false;

    /**
     * Handle the tracking events whe it's  fired
     */
    protected eventHandler: EventHandler;

    constructor(protected codes: Array<string>) {
        this.eventHandler = new EventHandler();

    }

    getCodes() {
        return this.codes;
    }
    /**
     * Get the first code for manage the url 
     * 
     * @returns 
     */
    getFirstCode(): string | null {
        if (this.codes.length > 0) {
            return this.codes[0];
        } else {
            return null
        }
    }

    subscribe(event: PrizificTrackingEvents, callback: (...params: Array<any>) => void) {
        this.eventHandler.subscribe(event, callback);
    }

    /**
     * Google Tag Manager enabled
     * @returns 
     */
    isEnabled(): boolean {
        return this.enabled;
    }
    /**
     * Enable google tag manager
     */
    enable() {
        this.enabled = true;
        this.eventHandler.fire(PrizificTrackingEvents.enable, arguments);
    }
    /**
     * Disable Google tag manager
     */
    disable() {
        this.enabled = false;
        this.eventHandler.fire(PrizificTrackingEvents.disable, arguments);
    }
    /**
     * Init if possible, the enable also try initialize when enabling the system
     */
    init() {
        this.eventHandler.fire(PrizificTrackingEvents.init, arguments);
    }
    /**
     * Wrapping the gtag 
     * 
     * @param args
     */
    gtag(...args: Array<any>) {
        if (process.client && this.isEnabled()) {

        }
    }

    /**
     * Track a given event
     */
    event(name: string, value?: any) {
        this.gtag('event', name, value)
    }
    /**
     * The given page view
     * 
     * @param title The page title if has if not the given route name
     * @param location The current location
     * @param path The current path
     */
    pageView(title: any, location: any, path: any) {
        const value = {
            page_title: title,
            page_location: location,
            page_path: path,
        }

        this.event('page_view', value);
        this.eventHandler.fire(PrizificTrackingEvents.pageView, arguments);
    }
    /**
     * need to fire when a user sign up int the system
     */
    signUp() {
        this.event('sign_up');
        this.eventHandler.fire(PrizificTrackingEvents.signUp, arguments);
    }
    /**
     * need to fire when the user is logging in into the system
     */
    login() {
        this.event('login');
        this.eventHandler.fire(PrizificTrackingEvents.login, arguments);
    }
    /**
     * Need to fire when the user upload a code to system
     */
    codeUpload() {
        this.event('code_upload');
        this.eventHandler.fire(PrizificTrackingEvents.codeUpload, arguments);
    }
    /**
     * Need to fire when the user uploaded the code into system (that means:  code was successfully uploaded)
     */
    codeUploadSuccess() {
        this.event('code_upload_success');
        this.eventHandler.fire(PrizificTrackingEvents.codeUploadSuccess, arguments);
    }
}



/*export default defineNuxtPlugin(async () => {


    if (!gtagPluginInstance) {
        gtagPluginInstance = new PrizGTAG([]);
    }

    try {
        const campaignDataStore = useCampaignDataStore();

        const scripts = await campaignDataStore.obtainTrackingScripts()

        console.log("Tracking scripts", scripts);
    } catch (e) {
        //console.error("Plugin init error", e);
    }
    console.log("Use Head From Plugin");
    useHead({
        script: [
          {
            innerHTML:"alert('add')",
            src: 'https://third-party-script.com',
            // valid options are: 'head' | 'bodyClose' | 'bodyOpen'
            tagPosition: 'head'
          }
        ]
      })

    return {
        provide: {
            pGtag: gtagPluginInstance,
        },
    };
});*/


import { defineStore } from "pinia";
import { EPrizeType } from "~~/enums/Campaign/EPrizeType";
import moment from "moment";


interface TrackingState {

    statisticScripts: Array<any>;
}

var pGtagInstance: PrizGTAG


function singletonPrizGtag() {
    if (!pGtagInstance) {
        pGtagInstance = new PrizGTAG([]);
    }

    return pGtagInstance;
}


export const useTrackingStore = defineStore("trackingStore", {
    state: (): TrackingState => {
        return {
            statisticScripts: []
        };
    },

    actions: {

        async init(enabled: boolean) {


            //console.log("statisticScripts", this.statisticScripts);
            const campaignDataStore = useCampaignDataStore();

            const scripts = await campaignDataStore.obtainTrackingScripts()

            singletonPrizGtag().subscribe(PrizificTrackingEvents.enable, () => {

                const enableScripts = scripts.filter((script: any) => {
                    return script.type == PrizificTrackingEvents.enable
                })

                enableScripts.forEach((script: any) => {
                    useHead({
                        script: [
                            {
                                key: script.type,
                                id: "script-enabled",
                                innerHTML: script.script,
                                // valid options are: 'head' | 'bodyClose' | 'bodyOpen'
                                tagPosition: 'head'
                            }
                        ]
                    })
                });

            })

            singletonPrizGtag().subscribe(PrizificTrackingEvents.disable, () => {
                
                const enableScripts = scripts.filter((script: any) => {
                    return script.type == PrizificTrackingEvents.enable
                })

                enableScripts.forEach((script: any) => {
                    const head = useHead({
                        script: [
                            {
                                key: script.type,
                                id: "script-enabled-remove",
                                innerHTML: "",
                                // valid options are: 'head' | 'bodyClose' | 'bodyOpen'
                                tagPosition: 'head'
                            }
                        ]
                    });
                });
            });

            singletonPrizGtag().init();

            if (enabled) {
                singletonPrizGtag().enable()
            }

            //console.log("subscribedHeads", this.statisticScripts);
        },
        enable() {
            singletonPrizGtag().enable()
        },
        disable() {
            singletonPrizGtag().disable()
        }
    }
})