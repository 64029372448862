import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import EditPassword from "@/entities/PrizificPageBuilder/sections/EditPassword";
import { RuntimeComponentParseError } from "@/error/RuntimeError";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";

export class EditPasswordFactory extends AComponentFactory<EditPassword> {
    getTypeName() {
        return EComponents.EDIT_PASSWORD;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            "data.specific.sectionTitleCompiled",
            "data.specific.sectionParagraphCompiled",
            "data.specific.sectionTitle",
            "data.specific.sectionParagraph",
        ]);

        return new EditPassword(
            rawData.id,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific.sectionTitleCompiled),
            getConvertedText(rawData.data.specific.sectionParagraphCompiled),
            rawData.data.specific.sectionTitle,
            rawData.data.specific.sectionParagraph
        );
    }
}
