import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import ContactWithPrivacyPolicy from "@/entities/PrizificPageBuilder/sections/ContactWithPrivacyPolicy";
import { RuntimeComponentParseError } from "@/error/RuntimeError";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import {
    contactFontFamily,
    contactBackground,
    contactTitleColor,
    contactTitleFontSize,
    contactParagraphColor,
    contactParagraphFontSize,
    contactCheckboxBackground,
    contactCheckboxColor,
    contactCheckboxBorderRadius,
    contactSubmitButtonPadding,
    contactSubmitButtonFontSize,
    contactSubmitButtonBackground,
    contactSubmitButtonTextColor,
    contactSubmitButtonBorderColor,
    contactSubmitButtonBorderWidth,
    contactSubmitButtonBorderRadius,
    contactSubmitButtonBackgroundHover,
    contactSubmitButtonTextColorHover,
    contactSubmitButtonBorderColorHover,
    contactSubmitButtonBorderWidthHover,
} from "~~/entities/pages/shared/sections/ContactWithPrivacyPolicy/ContactWithPrivacyPolicyPropterties";

export class ContactWithPrivacyPolicyFactory extends AComponentFactory<ContactWithPrivacyPolicy> {
    getTypeName() {
        return EComponents.CONTACT_WITH_PRIVACY_POLICY;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.sectionTitleCompiled",
            // "data.specific.sectionParagraphCompiled",
            // "data.specific.sectionTitle",
            // "data.specific.sectionParagraph",
            // "data.specific.images"
        ]);

        return new ContactWithPrivacyPolicy(
            rawData.id,
            rawData.sharedComponentId ?? null,
            rawData.isSharedComponent ?? false,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific.sectionTitleCompiled),
            getConvertedText(rawData.data.specific.sectionParagraphCompiled),
            rawData.data.specific.sectionTitle,
            rawData.data.specific.sectionParagraph,
            rawData.data.specific.images
                ? rawData.data.specific.images
                : [
                      {
                          src: "",
                          imageType: "extra-extra-large",
                          imageLabel: "1920px",
                      },
                      {
                          src: "",
                          imageType: "extra-large",
                          imageLabel: "1400px",
                      },
                      {
                          src: "",
                          imageType: "large",
                          imageLabel: "1200px",
                      },
                      {
                          src: "",
                          imageType: "medium",
                          imageLabel: "992px",
                      },
                      {
                          src: "",
                          imageType: "small",
                          imageLabel: "768px",
                      },
                      {
                          src: "",
                          imageType: "extra-small",
                          imageLabel: "576px",
                      },
                  ]
        );
    }

    createPlain() {
        return new ContactWithPrivacyPolicy(
            "-",
            null,
            false,
            "Contact with privacy policy",
            [
                contactFontFamily,
                contactBackground,
                contactTitleColor,
                contactTitleFontSize,
                contactParagraphColor,
                contactParagraphFontSize,
                contactCheckboxBackground,
                contactCheckboxColor,
                contactCheckboxBorderRadius,
                contactSubmitButtonPadding,
                contactSubmitButtonFontSize,
                contactSubmitButtonBackground,
                contactSubmitButtonTextColor,
                contactSubmitButtonBorderColor,
                contactSubmitButtonBorderWidth,
                contactSubmitButtonBorderRadius,
                contactSubmitButtonBackgroundHover,
                contactSubmitButtonTextColorHover,
                contactSubmitButtonBorderColorHover,
                contactSubmitButtonBorderWidthHover,
            ],
            "",
            [],
            getConvertedText("Kapcsolat"),
            getConvertedText(""),
            "Kapcsolat",
            ""
        );
    }
}
