import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~/enums/PrizificPageBuilder/EComponents";
import UserConsenting from "~/entities/PrizificPageBuilder/sections/UserConsenting";
import { EHiveLoginText } from "~~/enums/PrizificPageBuilder/EHiveLoginText";
import Text from "~~/entities/PrizificPageBuilder/components/Text";
import { userConsentingGroup } from "~~/entities/pages/home/sections/UserConsenting/components/UserConsentingGroup";
import {
    userConsentingBackground,
    userConsentingTitleColor,
    userConsentingTitleFontSize,
    userConsentingParagraphColor,
    userConsentingParagraphFontSize,
    userConsentingPrimaryColor,
    userConsentingSecondaryColor,
} from "~~/entities/pages/home/sections/UserConsenting/UserConsentingProperty";

export class UserConsentingFactory extends AComponentFactory<UserConsenting> {
    getTypeName() {
        return EComponents.USER_CONSENTING;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.sectionTitleCompiled",
            // "data.specific.sectionParagraphCompiled",
            // "data.specific.sectionTitle",
            // "data.specific.sectionParagraph",
        ]);

        return new UserConsenting(
            rawData.id,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents
        );
    }

    createPlain() {
        return new UserConsenting(
            "-",
            "User consent",
            [
                userConsentingBackground,
                userConsentingTitleColor,
                userConsentingTitleFontSize,
                userConsentingParagraphColor,
                userConsentingParagraphFontSize,
                userConsentingPrimaryColor,
                userConsentingSecondaryColor,
            ],
            "",
            [userConsentingGroup]
        );
    }
}
