import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { RuntimeComponentParseError } from "@/error/RuntimeError";
import { AComponentFactory } from "../ComponentFactory";
import Button from "@/entities/PrizificPageBuilder/components/Button";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";

export class ButtonFactory extends AComponentFactory<Button> {
    getTypeName() {
        return EComponents.BUTTON;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.textCompiled",
            // "data.specific.text",
        ]);

        return new Button(
            rawData.id,
            rawData.sharedComponentId ?? null,
            rawData.isSharedComponent ?? false,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific.textCompiled),
            rawData.data.specific.text
        );
    }
}
