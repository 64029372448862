import plugin_vue3_A0OWXRrUgq from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_M7DKUOwKp5 from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import boostrap_client_kZRInXHYZw from "/opt/atlassian/pipelines/agent/build/plugins/boostrap.client.ts";
import element_plus_HUzmwi0JYR from "/opt/atlassian/pipelines/agent/build/plugins/element-plus.ts";
import vue_draggable_OKRqLUiGtd from "/opt/atlassian/pipelines/agent/build/plugins/vue-draggable.ts";
import vue_tel_input_client_Cyn6ok7DxA from "/opt/atlassian/pipelines/agent/build/plugins/vue-tel-input.client.ts";
import quill_editor_client_tupKYLQr8r from "/opt/atlassian/pipelines/agent/build/plugins/quill-editor.client.ts";
import ssr_plugin_B4ptqVdIfe from "/opt/atlassian/pipelines/agent/build/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import PageBuilderFactory_q3JaAEzo5V from "/opt/atlassian/pipelines/agent/build/plugins/PageBuilderFactory.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  vueuse_head_polyfill_M7DKUOwKp5,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  chunk_reload_client_UciE0i6zes,
  boostrap_client_kZRInXHYZw,
  element_plus_HUzmwi0JYR,
  vue_draggable_OKRqLUiGtd,
  vue_tel_input_client_Cyn6ok7DxA,
  quill_editor_client_tupKYLQr8r,
  ssr_plugin_B4ptqVdIfe,
  PageBuilderFactory_q3JaAEzo5V
]