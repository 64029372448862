import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { AComponentFactory } from "../ComponentFactory";
import FormItem from "@/entities/PrizificPageBuilder/components/FormItem";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";

export class FormItemFactory extends AComponentFactory<FormItem> {
    getTypeName() {
        return EComponents.FORM_ITEM;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.labelCompiled",
            // "data.specific.placeholderCompiled",
            // "data.specific.label",
            // "data.specific.placeholder",
            // "data.specific.belongsTo",
        ]);

        return new FormItem(
            rawData.id,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific.labelCompiled),
            getConvertedText(rawData.data.specific.placeholderCompiled),
            rawData.data.specific.label,
            rawData.data.specific.placeholder,
            rawData.data.specific.belongsTo
        );
    }
}
