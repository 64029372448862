export enum ERegistrationFormItem {
    EMAIL = "EMAIL",
    PASSWORD = "PASSWORD",
    FIRSTNAME = "FIRSTNAME",
    LASTNAME = "LASTNAME",
    PHONE_NUMBER = "PHONE_NUMBER",
    DATE_OF_BIRTH_YEAR = "DATE_OF_BIRTH_YEAR",
    DATE_OF_BIRTH_MONTH = "DATE_OF_BIRTH_MONTH",
    DATE_OF_BIRTH_DAY = "DATE_OF_BIRTH_DAY",
    ZIP = "ZIP",
    CITY = "CITY",
    ADDRESS = "ADDRESS",
    EDM_SUBSCRIPTION = "EDM_SUBSCRIPTION",
    PRIVACY_POLICY = "PRIVACY_POLICY",
}
