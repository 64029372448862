import { defineStore } from "pinia";
import { useCampaignDataStore } from "../campaign/campaignData";
import { useVoteStore } from "../components/vote/vote";

/**
 * Represent a user
 */
interface User {
    id: number;
    uuid: string;
    campaignId: number;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    edmSubscription: boolean;
    lastLoginAt: string;
    activated: string;
    createdAt: string;
    activatedAt: string;
    uploadedCodesCount: boolean;
    alreadyWon: boolean;
    deleteAvailable: boolean;
}

export interface Token {
    accessToken: string;
    accessTokenLifetime: any;
    refreshToken: string;
}

interface AuthState {
    user?: User;
}

interface ConsentPageCustomization {
    sectionBackground: { [key: string]: string | number };
    sectionTitle: { [key: string]: string | number };
    sectionParagraph: { [key: string]: string | number };
    sectionPrimaryColor: { [key: string]: string | number };
    sectionSecondaryColor: { [key: string]: string | number };
    formBackground: { [key: string]: string | number };
    formItemLabel: { [key: string]: string | number };
    formItemInput: { [key: string]: string | number };
    formItemCheckbox: { [key: string]: string | number };
    formSubmitButton: { [key: string]: string | number };
    formSubmitButtonHover: { [key: string]: string | number };
    loginEmail: { [key: string]: string | number };
    loginPassword: { [key: string]: string | number };
    registrationFirstName: { [key: string]: string | number };
    registrationLastName: { [key: string]: string | number };
    registrationEmail: { [key: string]: string | number };
    registrationPhoneNumber: { [key: string]: string | number };
    registrationZip: { [key: string]: string | number };
    registrationCity: { [key: string]: string | number };
    registrationAddress: { [key: string]: string | number };
}

export const useAuthStore = defineStore("authStore", {
    state: (): AuthState => {
        return {
            user: undefined,
        };
    },
    getters: {
        isAuthenticated(): boolean {
            return this.user !== undefined;
        },
        activatedRegistration(): boolean {
            return this.user ? Boolean(this.user.activatedAt) : false;
        },
        isTokenExpired(): boolean {
            let expired = false;

            if (this.token && this.token.accessTokenLifetime !== undefined) {
                expired = this.token.accessTokenLifetime < Date.now();
            }
            return expired;
        },
        authCookie() {
            return () => {
                const campaignDataStore = useCampaignDataStore();

                const authCookie = useCookie("auth", {
                    default: (): Token | undefined => undefined,
                    watch: true,
                    maxAge: 31622400,
                    path: campaignDataStore.campaignData.campaignPrefix,
                });

                return authCookie;
            };
        },
        oAuthChallengeCookie() {
            const campaignDataStore = useCampaignDataStore();
            const cookie = useCookie("oauth-challenge", {
                default: (): { [key: string]: string } => {
                    return {};
                },
                watch: true,
                maxAge: 60 * 60 * 12,
                sameSite: "none",
                secure: true,
                path: campaignDataStore.campaignData.campaignPrefix,
            });

            return cookie;
        },
        token(): Token | undefined {
            if (this.authCookie().value) {
                const token = this.authCookie().value;
                return token;
            }
            return undefined;
        },
        accessToken(): string {
            return this.authCookie()?.value?.accessToken ?? "---";
        },
        /**
         * add path and dominat like
         * if you  want: https://nyeromarkak.hu/xyz
         * The input have to be /xyz
         *
         */
        hiveUrl() {
            return (path: string) => {
                let host = "https://nyeromarkak.hu";
                //Super Hive Temp Solution
                const user: any = this.user;

                if (
                    user &&
                    user.hiveId == "4a025dac-2147-4887-9bed-376e7e09e18a"
                ) {
                    host = "https://prizific.com";
                }

                if (
                    user &&
                    user.hiveId == "932c30be-4094-4f23-8f68-6db779a695fc"
                ) {
                    host = "https://szentkiralyi.prizific.com";
                }

                return host + path;
            };
        },
    },
    actions: {
        async setToken(token: Token) {
            this.authCookie().value = token;
        },
        /**
         * Get the current user profile
         */
        async getMe() {
            //INFO: tempsolution
            const token = this.authCookie().value?.accessToken;

            //console.log(token);

            const { data, error } = await useApiFetch(
                "/endusermanagerms/profile",
                {
                    key: "get-user-profile",
                    method: "GET",
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            this.user = data.value.profile as User;

            const voteStore = useVoteStore();
            voteStore.getVoted();

            return this.user;
        },
        /**
         * Login with Oauth2 to prizific Hive system (hardcoded)
         * TODO: make as dynamic handling
         * Build the redirect link and setup the HIVE connection  (make a new on every call )
         *
         */
        async oauth2LoginStart(
            campaignUUId: string,
            consentPageCustomization?: ConsentPageCustomization
        ) {
            const internalConsentPageCustomization: ConsentPageCustomization =
                consentPageCustomization ?? ({} as ConsentPageCustomization);

            //const { $axios } = useNuxtApp();
            const campaignDataStore = useCampaignDataStore();

            let stateAndCodeUrl = "/make-state-and-code";

            if (
                campaignDataStore.campaignData &&
                campaignDataStore.campaignData.campaignPrefix
            ) {
                stateAndCodeUrl =
                    campaignDataStore.campaignData.campaignPrefix +
                    "/make-state-and-code";
            }

            const response = await $fetch(stateAndCodeUrl);

            const verifier = response.verifier;
            const hash = response.hash;
            const state = response.state;

            //const verifier = this.makeRandomString(128);
            //
            //const textAsBuffer = new TextEncoder().encode(verifier);
            //const hashBuffer = await window.crypto.subtle.digest("SHA-256", textAsBuffer);
            //
            //const ie11SafeInput = new Uint8Array(hashBuffer);
            //const b64Challenge = this.urlEncodeB64(window.btoa(String.fromCharCode(...Array.from(ie11SafeInput))));
            //

            const b64ChallengeNew = this.urlEncodeB64(window.btoa(hash));

            const runtimeConfig = useRuntimeConfig();

            let hiveURl = runtimeConfig.hiveConsentUrl;

            //Default client id (Simple UHU)
            let clientId = runtimeConfig.hiveClientId;
            let hiveAuthRedirectUrl = runtimeConfig.hiveAuthRedirectUrl;
            //TODO: remove this when the new solution was done
            try {
                console.log(
                    "Campaign UUID",
                    campaignUUId,
                    runtimeConfig.vasaroljEsNyerjCampaignUUId,
                    campaignUUId == runtimeConfig.vasaroljEsNyerjCampaignUUId
                );

                /*if (campaignUUId == runtimeConfig.uhuCampaignUUId) {
                } else if (campaignUUId == runtimeConfig.uhuTescoCampaignUUId) {
                    clientId = runtimeConfig.hiveUhuTescoClientId;
                    hiveAuthRedirectUrl =
                        runtimeConfig.hiveUhuTescoAuthRedirectUrl;
                } else if (
                    campaignUUId ==
                    runtimeConfig.hiveKellogsBackToSchoolCampaignUUId
                ) {
                    clientId = runtimeConfig.hiveKellogsBackToSchoolClientId;
                    hiveAuthRedirectUrl =
                        runtimeConfig.hiveKellogsBackToSchoolAuthRedirectUrl;
                } else if (
                    campaignUUId == runtimeConfig.legyenBuilCampaignUUId
                ) {
                    clientId = runtimeConfig.legyenBuilClientId;
                    hiveAuthRedirectUrl =
                        runtimeConfig.legyenBuilAuthRedirectUrl;
                } else if (
                    campaignUUId == runtimeConfig.vasaroljEsNyerjCampaignUUId
                ) {
                    clientId = runtimeConfig.vasaroljEsNyerjClientId;
                    hiveAuthRedirectUrl =
                        runtimeConfig.vasaroljEsNyerjAuthRedirectUrl;
                    console.log("runtimeConfig.vasaroljEsNyerjAuthRedirectUrl", runtimeConfig.vasaroljEsNyerjAuthRedirectUrl);
                }*/

                if (campaignUUId == runtimeConfig.vasaroljEsNyerjCampaignUUId) {
                    clientId = runtimeConfig.vasaroljEsNyerjClientId;
                }

                if (campaignUUId == runtimeConfig.pringlesCampaignUUId) {
                    clientId = runtimeConfig.pringlesClientId;
                }

                if (campaignUUId == runtimeConfig.noipromoCampaignUUId) {
                    clientId = runtimeConfig.noipromoClientId;
                }

                if (campaignUUId == runtimeConfig.ferfipromoCampaignUUId) {
                    clientId = runtimeConfig.ferfipromoClientId;
                }

                if (campaignUUId == runtimeConfig.omniaCampaignUUId) {
                    clientId = runtimeConfig.omniaClientId;
                    hiveURl = runtimeConfig.hiveConsentSuperHiveUrl;
                }

                if (campaignUUId == runtimeConfig.uhuOkosoraCampaignUUId) {
                    clientId = runtimeConfig.uhuOkosoraClientId;
                }

                if (campaignUUId == runtimeConfig.pgParalimpiaCampaignUUId) {
                    clientId = runtimeConfig.pgParalimpiaClientId;
                }


                if (campaignUUId == runtimeConfig.szentkiralyiCampaignUUId) {
                    clientId = runtimeConfig.szentkiralyiClientId;
                    hiveURl = runtimeConfig.hiveConsentSzentrkiralyiHiveUrl;
                }

                if (campaignUUId == runtimeConfig.kellogsCampaignUUId) {
                    clientId = runtimeConfig.kellogsClientId;
                }

                if (campaignDataStore.isPageBuilder) {
                    hiveAuthRedirectUrl =
                        runtimeConfig.apiUrlScheme +
                        "://" +
                        campaignUUId +
                        runtimeConfig.pageBuilderPart +
                        campaignDataStore.campaignData.campaignPrefix +
                        "/o-auth";
                } else if (campaignDataStore.isPreview) {
                    hiveAuthRedirectUrl =
                        runtimeConfig.apiUrlScheme +
                        "://" +
                        campaignUUId +
                        runtimeConfig.preview +
                        campaignDataStore.campaignData.campaignPrefix +
                        "/o-auth";
                } else {
                    hiveAuthRedirectUrl =
                        runtimeConfig.apiUrlScheme +
                        "://" +
                        campaignDataStore.dynamicCampaignApiUrlPart +
                        "/o-auth";
                }
            } catch (e) {
                console.log("Error", e);
            }

            const searchParams = new URLSearchParams({
                client_id: clientId,
                redirect_uri: hiveAuthRedirectUrl,
                response_type: "code",
                state: state,
                code_challenge: b64ChallengeNew,
                code_challenge_method: "S256",
                scope: "",
                customization: JSON.stringify(internalConsentPageCustomization),
            });

            console.log("searchParams", searchParams);

            this.oAuthChallengeCookie.value[state] = verifier;

            return hiveURl + "?" + searchParams.toString();
        },
        generateUUID() {
            // Public Domain/MIT
            var d = new Date().getTime(); //Timestamp
            var d2 =
                (typeof performance !== "undefined" &&
                    performance.now &&
                    performance.now() * 1000) ||
                0; //Time in microseconds since page-load or 0 if unsupported
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
                /[xy]/g,
                function (c) {
                    var r = Math.random() * 16; //random number between 0 and 16
                    if (d > 0) {
                        //Use timestamp until depleted
                        r = (d + r) % 16 | 0;
                        d = Math.floor(d / 16);
                    } else {
                        //Use microseconds since page-load if supported
                        r = (d2 + r) % 16 | 0;
                        d2 = Math.floor(d2 / 16);
                    }
                    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
                }
            );
        },
        urlEncodeB64(url: string): string {
            const b64Chars: any = { "+": "-", "/": "_", "=": "" };
            return url.replace(/[\+\/=]/g, (m: string): string => b64Chars[m]);
        },
        async oauth2LoginGetToken() { },
        makeRandomString(length: number) {
            let result = "";
            const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                );
                counter += 1;
            }
            return result;
        },
        /**
         * Log out the current user
         */
        async logOut() {
           
            const { data, error } = await useApiFetch(
                "/endusermanagerms/auth/logout",
                {
                    key: "logout",
                    method: "POST",
                    headers: {
                        authorization: `Bearer ${this.accessToken}`,
                    },
                }
            );

            this.user = undefined;
            this.authCookie().value = undefined;
        },

        async oAuthAuthorize(
            campaignUuid: string,
            requestData: any,
            campaignPrefix: string
        ) {
            //const { $axios } = useNuxtApp();

            const parameters = {
                ...requestData,
                campaignUuid: campaignUuid,
            };

            const campaignDataStore = useCampaignDataStore();
            let hiveAuthCodeUrl = "/hive-auth-code";

            if (campaignPrefix) {
                hiveAuthCodeUrl =
                    campaignDataStore.campaignData.campaignPrefix +
                    "/hive-auth-code";
            }

            const data = await $fetch(hiveAuthCodeUrl, {
                params: parameters,
            });

            console.log("Response Token:", data);

            this.authCookie().value = data;
        },
    },
});
