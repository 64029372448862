import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { IButton } from "@/interfaces/PrizificPageBuilder/IButton";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";
import ASharedComponent from "../ASharedComponent";

export default class Button
    extends ASharedComponent<EComponents.BUTTON>
    implements IComponent<EComponents.BUTTON>, IButton
{
    constructor(
        id: string,
        sharedComponentId: string | number | null,
        isSharedComponent: boolean,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public text: string, //Compiled
        public textRaw: string //Not compiled
    ) {
        super(
            id,
            sharedComponentId,
            isSharedComponent,
            title,
            EComponents.BUTTON,
            style,
            classNames,
            components
        );
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            text: this.textRaw,
        });
    }
}
