import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import TimeInterval from "@/entities/PrizificPageBuilder/sections/TimeInterval";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";

export class TimeIntervalFactory extends AComponentFactory<TimeInterval> {
    getTypeName() {
        return EComponents.TIME_INTERVAL;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.sectionTitleCompiled",
            // "data.specific.sectionParagraphCompiled",
            // "data.specific.sectionTitle",
            // "data.specific.sectionParagraph",
        ]);

        return new TimeInterval(
            rawData.id,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents,
            getConvertedText(rawData.data.specific.beforeCampaignTextCompiled),
            rawData.data.specific.beforeCampaignText,
            getConvertedText(rawData.data.specific.activeCampaignTextCompiled),
            rawData.data.specific.activeCampaignText,
            getConvertedText(rawData.data.specific.afterCampaignTextCompiled),
            rawData.data.specific.afterCampaignText
        );
    }
}
