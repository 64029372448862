import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { AComponentFactory } from "../ComponentFactory";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    discountCouponsFontFamily,
    discountCouponsBackground,
    discountCouponsImageWidth,
    discountCouponsTextColor,
    discountCouponsMoreTextColor,
    discountCouponsMoreHoverTextColor,
    discountCouponsTypeFontSize,
    discountCouponsPrizeNameFontSize,
    discountCouponsDescriptionFontSize,
    discountCouponsMoreFontSize,
} from "@/entities/pages/home/sections/DiscountCoupons/DiscountCouponsProperties";
import DiscountCoupons from "../../entities/PrizificPageBuilder/sections/DiscountCoupons";

export class DiscountCouponsFactory extends AComponentFactory<DiscountCoupons> {
    getTypeName() {
        return EComponents.DISCOUNT_COUPONS;
    }

    create(
        rawData: any,
        subComponents: IComponent<any>[],
        properties: IProperty[]
    ) {
        this.validate(rawData, [
            // "data.specific.sectionTitleCompiled",
            // "data.specific.sectionParagraphCompiled",
            // "data.specific.sectionTitle",
            // "data.specific.sectionParagraph",
            // "data.specific.images"
        ]);

        return new DiscountCoupons(
            rawData.id,
            rawData.data.title,
            properties,
            rawData.data.classNames,
            subComponents,
            rawData.data.specific.sectionTitleCompiled
                ? getConvertedText(rawData.data.specific.sectionTitleCompiled)
                : "",
            rawData.data.specific.sectionParagraphCompiled
                ? getConvertedText(
                      rawData.data.specific.sectionParagraphCompiled
                  )
                : "",
            rawData.data.specific.sectionTitle
                ? rawData.data.specific.sectionTitle
                : "",
            rawData.data.specific.sectionParagraph
                ? rawData.data.specific.sectionParagraph
                : "",
            rawData.data.specific.images
                ? rawData.data.specific.images
                : [
                      {
                          src: "",
                          imageType: "extra-extra-large",
                          imageLabel: "1920px",
                      },
                      {
                          src: "",
                          imageType: "extra-large",
                          imageLabel: "1400px",
                      },
                      {
                          src: "",
                          imageType: "large",
                          imageLabel: "1200px",
                      },
                      {
                          src: "",
                          imageType: "medium",
                          imageLabel: "992px",
                      },
                      {
                          src: "",
                          imageType: "small",
                          imageLabel: "768px",
                      },
                      {
                          src: "",
                          imageType: "extra-small",
                          imageLabel: "576px",
                      },
                  ]
        );
    }

    createPlain() {
        return new DiscountCoupons(
            "-",
            "Discount coupons",
            [
                discountCouponsFontFamily,
                discountCouponsBackground,
                discountCouponsImageWidth,
                discountCouponsTextColor,
                discountCouponsMoreTextColor,
                discountCouponsMoreHoverTextColor,
                discountCouponsTypeFontSize,
                discountCouponsPrizeNameFontSize,
                discountCouponsDescriptionFontSize,
                discountCouponsMoreFontSize,
            ],
            "",
            [],
            getConvertedText("Default title"),
            getConvertedText("Default paragraph"),
            "Default title",
            "Default paragraph"
        );
    }
}
