import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { IFormItem } from "~/interfaces/PrizificPageBuilder/IFormItem";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";
import { ECodeUploadFormItem } from "~/enums/PrizificPageBuilder/ECodeUploadFormItem";
import { ELoginFormItem } from "~/enums/PrizificPageBuilder/ELoginFormItem";
import { ENewPasswordFormItem } from "~~/enums/PrizificPageBuilder/ENewPasswordFormItem";
import { EShippingAddressFormItem } from "~~/enums/PrizificPageBuilder/EShippingAddressFormItem";
import { ERecipeUploadFormItem } from "~~/enums/PrizificPageBuilder/ERecipeUploadFormItem";
import { EEditProfileFormItem } from "~~/enums/PrizificPageBuilder/EEditProfileFormItem";
import { ERegistrationFormItem } from "~~/enums/PrizificPageBuilder/ERegistrationFormItem";

export default class FormItem
    extends AComponent<EComponents.FORM_ITEM>
    implements IComponent<EComponents.FORM_ITEM>, IFormItem
{
    constructor(
        id: string,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,
        public label: string,
        public placeholder: string,
        public labelRaw: string,
        public placeholderRaw: string,
        public belongsTo:
            | ECodeUploadFormItem
            | ELoginFormItem
            | ERegistrationFormItem
            | ENewPasswordFormItem
            | EShippingAddressFormItem
            | ERecipeUploadFormItem
            | EEditProfileFormItem,
        public checkboxText?: string,
        public checkboxTextRaw?: string
    ) {
        super(id, title, EComponents.FORM_ITEM, style, classNames, components);
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            label: this.labelRaw,
            placeholder: this.placeholderRaw,
            checkboxText: this.checkboxTextRaw,
            belongsTo: this.belongsTo,
        });
    }
}
