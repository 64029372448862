import { IComponent } from "@/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "@/interfaces/PrizificPageBuilder/IProperty";
import { ISection } from "@/interfaces/PrizificPageBuilder/ISection";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import AComponent from "../AComponent";

export default class UserConsenting
    extends AComponent<EComponents.USER_CONSENTING>
    implements IComponent<EComponents.USER_CONSENTING>
{
    constructor(
        id: string,
        title: string,
        style: Array<IProperty>,
        classNames: string,
        components: Array<IComponent<EComponents>>,

    ) {
        super(
            id,
            title,
            EComponents.USER_CONSENTING,
            style,
            classNames,
            components
        );
    }

    toPayload(): { [key: string]: any } {
        return super.toPayload({
            
            //guestUserTitle: this.guestUserTitle,
            //guestUserDescription: this.guestUserDescription,
            //loggedInUserTitle: this.loggedInUserTitle,
            //loggedInUserDescription: this.loggedInUserDescription,
        });
    }
}
